<template>
   <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item>
              <v-chip :disabled="item.disabled" style="opacity:0.8;" color="primary" @click="$router.push(item.href)">
                  {{ item.text }}
              </v-chip>
          </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'Breadcrumbs',
     computed: {
        ...mapState({
            breadcrumbs: state => state.breadcrumbs
        }),
    }
  }
</script>